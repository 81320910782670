import React from 'react'
import Container from '../components/Container'
import Layout from '../components/Layout/Layout'
import ListPosts from '../components/Lists/ListPosts'
import SideBar from '../components/SideBar/SideBar'
import SideBarContent from '../components/SideBar/SideBarContent'
import { usePostsQuery } from '../hooks/usePostsQuery'
import { useSiteDescription } from '../hooks/useSiteDescription'

const IndexPage = () => {

  const { generalSettingsTitle, generalSettingsDescription, } = useSiteDescription().wp.allSettings

  // Get all latest Posts
  const posts = usePostsQuery()

  // Convert object of Posts to merged array
  const allPosts = Object.values(posts).map(post => post.edges).flat(1)

  return (
    <Layout isHome homeDescription={ generalSettingsDescription } homeTitle={ generalSettingsTitle } >
      <Container variant="aside">
        { allPosts &&
          <>
            <ListPosts posts={ allPosts } />
            <SideBar>
              <SideBarContent title="Популярное" variant="sticky">

              </SideBarContent>
            </SideBar>
          </>
        }
      </Container>
    </Layout>
  )
}

export default IndexPage;
