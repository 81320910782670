import { useStaticQuery, graphql } from "gatsby";

export const useSiteDescription = () => {
  const data = useStaticQuery(graphql`
    query MyQuery {
      wp {
        allSettings {
          generalSettingsTitle
          generalSettingsDescription
        }
      }
    }
  `)
  return data
}